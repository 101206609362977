<template>

  <div class="service__request sr__listPage">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              My Vendor Payments
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <!-- stock status -->
        <!-- <b-form-radio-group
          id="stock-status"
          v-model="status"
          button-variant="outline-primary"
          buttons
          class="spaceBetweenRadio radio-group-select"
        >
          <b-form-radio
            value="low stock"
            class="status-radio-btn"
          >
            <span class="d-sm-inline">Low Stock <span class="rounded-number">{{ lowStockCount }}</span></span>
          </b-form-radio>
          <b-form-radio
            value="out of stock"
            class="status-radio-btn"
          >
            <span class="d-sm-inline">Out of Stock <span class="rounded-number">{{ outOfStockCount }}</span></span>
          </b-form-radio>
          <b-form-radio
            value="all"
            class="status-radio-btn"
          >
            <span class="d-sm-inline">All Stock <span class="rounded-number">{{ allStockCount }}</span></span>
          </b-form-radio>
        </b-form-radio-group> -->
        <div class="demo-inline-spacing radio-group-select">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :class="{ 'button-active': invoiceType == 'all' }"
            @click="invoiceType = 'all'"
          >
            <span class="d-sm-inline">All My Invoices <span class="ml-25 rounded-number">{{ allInvoiceCount }}</span></span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :class="{ 'button-active': invoiceType == 'departmentPending' }"
            @click="clearAllFilters(); invoiceType = 'departmentPending'"
          >
            <span class="d-sm-inline">Pending Dept/HQ's Review <span class="ml-25 rounded-number">{{ departmentPendingCount }}</span></span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :class="{ 'button-active': invoiceType == 'cpPending' }"
            @click="clearAllFilters(); invoiceType = 'cpPending'"
          >
            <span class="d-sm-inline">Pending CP's Review <span class="ml-25 rounded-number">{{ cpPendingCount }}</span></span>
          </b-button>
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            right
            split
            style="height: 40px;"
            :class="{ 'button-active': ['thisPendingPayment', 'nextPendingPayment', 'allPendingPayment', 'paid'].includes(invoiceType) }"
            @click="dropdownButtonAction"
          >
            <template #button-content>
              <span class="d-sm-inline">{{ buttonText }} <span class="ml-25 rounded-number">{{ buttonCount }}</span></span>
            </template>
            <b-dropdown-item @click="clearAllFilters(); invoiceType = 'thisPendingPayment';">
              Pending Payment (This Cycle) - {{ thisPendingPaymentCount }}
            </b-dropdown-item>
            <b-dropdown-item @click="clearAllFilters(); invoiceType = 'nextPendingPayment';">
              Pending Payment (Next Cycle) - {{ nextPendingPaymentCount }}
            </b-dropdown-item>
            <b-dropdown-item @click="clearAllFilters(); invoiceType = 'allPendingPayment';">
              Pending Payment (All) - {{ allPendingPaymentCount }}
            </b-dropdown-item>
            <b-dropdown-item @click="clearAllFilters(); invoiceType = 'paid';">
              Paid - {{ paidCount }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            :class="{ 'button-active': invoiceType == 'mySignPending' }"
            @click="clearAllFilters(); invoiceType = 'mySignPending'"
          >
            <span class="d-sm-inline">Awaiting My Signature <span class="ml-25 rounded-number">{{ mySignPendingCount }}</span></span>
          </b-button>
        </div>
        <!-- ./ stock status  -->
        <!-- stock-list table  -->
        <div class="mt-2">
          <!-- Table Container Card -->
          <b-card
            no-body
            class="mb-0"
          >
            <div class="m-2">

              <!-- Table Top -->
              <b-row>
                <b-col
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                </b-col>
                <!-- Search -->
                <b-col
                  md="6"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <a
                      href="javascript:void(0)"
                      class="nav-link nav-link-search"
                      @click="showSearchBar = !showSearchBar"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        size="21"
                        class="mr-50"
                      />
                      <span class="align-middle">Search</span>
                    </a>
                    <b-input-group
                      v-if="showSearchBar"
                      class="input-group-merge mr-50"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        placeholder="Search..."
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="searchQuery = ''; showSearchBar = !showSearchBar"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-dropdown
                      v-if="['all', 'thisPendingPayment', 'nextPendingPayment', 'allPendingPayment', 'paid'].includes(invoiceType)"
                      id="dropdown-Team-form"
                      ref="filter_dropdown_invoice_list"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="dropdown-modal"
                      right
                      variant="link"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FilterIcon"
                          size="21"
                          class="align-middle mr-50"
                        />
                        <span class="mr-1">Filters</span>
                      </template>
                      <invoice-list-filter
                        :vendor.sync="vendor"
                        :vendor-options="vendorOptions"
                        :created-by.sync="createdBy"
                        :user-options="userOptions"
                        :entity.sync="entity"
                        :entity-options="entityOptions"
                        :payment-method.sync="paymentMethod"
                        :approval-status.sync="approvalStatus"
                        :date-type.sync="dateType"
                        :date-range.sync="dateRange"
                        :invoice-type="invoiceType"
                        @emitHideFilter="hideFilter"
                        @emitShowFilter="showFilter"
                      />
                    </b-dropdown>
                    <b-button
                      type="button"
                      variant="flat-primary"
                      class="float-right pt-0"
                      @click="downloadExcel()"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                        size="20"
                      />
                      <span class="">Download Excel</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <div v-if="vendor.length || entity.length || paymentMethod.length || approvalStatus.length || dateType || dateRange">
                <hr class="dividerHR filter-divider">
                <b-row>

                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="12"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <ul
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-if="dateType"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="dateType = ''; dateRange = ''"
                        >
                          {{ dateType }}
                        </b-form-tag>
                      </li>
                      <li
                        v-if="dateRange"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="dateRange = ''"
                        >
                          {{ dateRange }}
                        </b-form-tag>
                      </li>
                      <li
                        v-for="item in approvalStatus"
                        :key="item"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromApprovalStatusFilter(item)"
                        >
                          <span class="text-capitalize">{{ item }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        v-for="item in paymentMethod"
                        :key="item"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromPaymentMethodFilter(item)"
                        >
                          <span class="text-capitalize">{{ item }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        v-for="item in vendor"
                        :key="item"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromVendorFilter(item)"
                        >
                          <span class="text-capitalize">{{ resolveVendorName(item) }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        v-for="item in entity"
                        :key="item"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromEntityFilter(item)"
                        >
                          <span class="text-capitalize">{{ resolveEntityName(item) }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        class="list-inline-item"
                      >
                        <span
                          class="text-danger clear-filter-text mb-50"
                          @click="clearAllFilters"
                        >
                          Clear Filters
                        </span>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </div>

            <b-row class="no-gutters v-sticky-sidebar-container">
              <b-col
                sm="12"
                md="12"
                lg="12"
              >
                <b-table
                  ref="refInvoiceTable"
                  :items="fetchInvoiceLists"
                  responsive
                  class="position-relative min-height-table"
                  :fields="tableColumns"
                  primary-key="_id"
                  :sort-by.sync="sortBy"
                  show-empty
                  no-sort-reset
                  empty-text="No matching records found"
                  :sort-desc.sync="isSortDirDesc"
                >
                  <!-- Column: Item Details -->
                  <!-- <template #cell(itemName)="data">
                    <b-form-checkbox
                      :checked="selectedInvoiceItems.includes(data.item.item)"
                      @change="toggleSelectedInvoiceItems(data.item.item)"
                      @click.native.stop
                    >
                      <div class="d-flex">
                        <b-img
                          class="mb-1 mb-sm-0 user__avatar img-preview-block"
                          :src="data.item.image ? data.item.image : defaultImage"
                        />
                        <div
                          class="ml-1"
                        >
                          <span class="text-bold-black">{{ data.item.itemName || '-' }}</span>
                          <br>
                          <span class="text-muted">{{ data.item.sku || '-' }}</span>
                        </div>
                      </div>
                    </b-form-checkbox>
                  </template> -->
                  <template #cell(invoiceID)="data">
                    <div class="text-nowrap">
                      <div v-if="canViewThisAction('show', 'VendorPayment') || canViewThisAction('my-department-vp-show', 'MyVendorPayment') || canViewThisAction('my-vp-show', 'MyVendorPayment')">
                        <b-button
                          variant="link"
                          class="p-0 weight-600"
                          :to="{ name: 'purchasing-vendor-payment-single-view', params: { id: data.item._id }, query: { from: 'my-vp' } }"
                        >
                          {{ data.item.invoiceID }}
                        </b-button>
                      </div>
                      <div v-else>
                        <span>{{ data.item.invoiceID }}</span>
                      </div>
                    </div>
                  </template>

                  <template #cell(prCaseID)="data">
                    <div class="text-nowrap">
                      <div v-if="canViewThisAction('show', 'PurchaseRequest') || canViewThisAction('my-request-show', 'MyPurchaseRequest') || canViewThisAction('my-department-request-view', 'MyPurchaseRequest')">
                        <b-button
                          variant="link"
                          class="p-0 weight-600"
                          :to="{ name: 'purchasing-purchase-request-show', params: { id: data.item.purchaseRequest }, query: { tab: 'invoice', from: 'my-pr' } }"
                        >
                          {{ data.item.prCaseID }}
                        </b-button>
                      </div>
                      <div v-else>
                        <span>{{ data.item.prCaseID }}</span>
                      </div>
                      <span>{{ data.item.prID }}</span>
                    </div>
                  </template>
                  <!-- Column: Category -->
                  <template #cell(createdBy)="data">
                    <div
                      class=""
                    >
                      <span class="">{{ data.item.createdBy.name || '-' }}</span>
                    </div>
                    <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
                  </template>
                  <!-- Column: Status -->
                  <template #cell(deptCheckStatus)="data">
                    <div v-if="data.item.status == 'cancelled'">
                      <b-badge
                        variant="light-danger"
                        class="text-capitalize"
                      >
                        Cancelled
                      </b-badge>
                    </div>
                    <div v-else>
                      <div>
                        <b-badge
                          :variant="`light-${resolveInvoiceStatusVariant(data.item.deptCheckStatus)}`"
                          class="text-capitalize"
                        >
                          {{ data.item.deptCheckStatus }}
                        </b-badge>
                      </div>
                      <span v-if="data.item.deptCheckDate">
                        {{ dateFormatWithTime(data.item.deptCheckDate) }}
                      </span>
                      <span v-else-if="data.item.deptCheckStatus == 'pending'">
                        {{ data.item.deptPendingSignatory.name }}
                      </span>
                    </div>
                  </template>

                  <template #cell(cpCheckStatus)="data">
                    <div v-if="data.item.status == 'cancelled'">
                      <b-badge
                        variant="light-danger"
                        class="text-capitalize"
                      >
                        Cancelled
                      </b-badge>
                    </div>
                    <div v-else>
                      <div>
                        <b-badge
                          :variant="`light-${resolveInvoiceStatusVariant(data.item.cpCheckStatus)}`"
                          class="text-capitalize"
                        >
                          {{ data.item.cpCheckStatus }}
                        </b-badge>
                      </div>
                      <span v-if="data.item.cpCheckDate">
                        {{ dateFormatWithTime(data.item.cpCheckDate) }}
                      </span>
                      <span v-else-if="data.item.cpCheckStatus == 'pending'">
                        {{ data.item.cpUser.name }}
                      </span>
                    </div>
                  </template>
                  <template #cell(paymentStatus)="data">
                    <div v-if="data.item.paymentStatus || (data.item.deptCheckDate && data.item.cpCheckDate)">
                      <b-badge
                        :variant="`light-${resolveInvoiceStatusVariant(data.item.paymentStatus)}`"
                        class="text-capitalize"
                      >
                        <span v-if="data.item.paymentStatus">{{ data.item.paymentStatus }}</span>
                        <span v-else>To Process</span>
                      </b-badge>
                    </div>
                    <div v-else>
                      <span>-</span>
                    </div>
                    <span v-if="data.item.paymentStatus == 'Paid'">
                      {{ dateFormatWithTime(data.item.processedDate) }}
                    </span>
                    <span v-else-if="data.item.paymentStatus == 'Cancelled'">
                      {{ dateFormatWithTime(data.item.updatedAt) }}
                    </span>
                    <span v-else-if="data.item.financeRemarksStatus">
                      {{ data.item.financeRemarksStatus }}
                    </span>
                  </template>
                  <template #cell(totalPayable)="data">
                    <div
                      class="text-bold-black"
                    >
                      <span class="">$ {{ numberFormat(data.item.totalPayable) }}</span>
                    </div>
                    <span class="">{{ data.item.paymentMethod }}</span>
                  </template>
                  <!-- Column: Actions -->
                  <template
                    v-if="canViewThisAction('show', 'VendorPayment') || canViewThisAction('my-department-vp-show', 'MyVendorPayment') || canViewThisAction('my-vp-show', 'MyVendorPayment')"
                    #cell(actions)="data"
                  >
                    <div style="white-space: nowrap;">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >

                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item
                          v-if="canViewThisAction('show', 'VendorPayment') || canViewThisAction('my-department-vp-show', 'MyVendorPayment') || canViewThisAction('my-vp-show', 'MyVendorPayment')"
                          :to="{ name: 'purchasing-vendor-payment-single-view', params: { id: data.item._id }, query: { from: 'my-vp' } }"
                        >
                          <span class="align-middle ml-50">View</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>

                </b-table>
                <div
                  v-if="vendor.length == 1"
                  style="background: #F5F8FB; text-align: right; padding: 20px"
                  class="text-bold-black"
                >
                  <span style="margin-right: 10%; font-size: 18px;">
                    Grand Total $ {{ numberFormat(vendorTotal) }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-card>

          <div
            v-if="totalInvoice > perPage"
            class="mx-2 mb-2"
          >
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center pagination-content"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalInvoice"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  align="center"
                  prev-text="Prev"
                  next-text="Next"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                    <span>Prev</span>
                  </template>
                  <template #next-text>
                    <span>Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- ./ stock-list table  -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BCard, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BPagination, BFormTag, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { writeFile, utils } from 'xlsx'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

// eslint-disable-next-line import/no-cycle
import useAllInvoiceListList from './useInvoiceLists'
import invoiceListsStoreModule from './invoiceListsStoreModule'
import InvoiceListFilter from './InvoiceListFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BBadge,
    InvoiceListFilter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      topSpacing: 135,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      vendorOptions: [],
      entityOptions: [],
      userOptions: [],
      buttonText: 'Pending Payment (This Cycle)',
      buttonCount: 0,
      allInvoiceCount: 0,
      departmentPendingCount: 0,
      cpPendingCount: 0,
      thisPendingPaymentCount: 0,
      nextPendingPaymentCount: 0,
      allPendingPaymentCount: 0,
      paidCount: 0,
      mySignPendingCount: 0,
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },
  watch: {
    $route() {
      this.changeButtonText()
    },
  },
  created() {
    this.$http.get('purchase/vendor-payment/list/extra-options')
      .then(response => {
        this.vendorOptions = response.data.vendorOptions ?? []
        this.userOptions = response.data.userOptions ?? []
        this.entityOptions = response.data.entityOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('purchase/vendor-payment/my-list/data/count')
      .then(response => {
        this.allInvoiceCount = response.data.allInvoiceCount ?? 0
        this.departmentPendingCount = response.data.departmentPendingCount ?? 0
        this.cpPendingCount = response.data.cpPendingCount ?? 0
        this.thisPendingPaymentCount = response.data.thisPendingPaymentCount ?? 0
        this.nextPendingPaymentCount = response.data.nextPendingPaymentCount ?? 0
        this.allPendingPaymentCount = response.data.allPendingPaymentCount ?? 0
        this.paidCount = response.data.paidCount ?? 0
        this.mySignPendingCount = response.data.mySignPendingCount ?? 0

        this.changeButtonText()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_invoice_list.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_invoice_list.show(true)
    },
    downloadExcel() {
      this.$http.get('purchase/vendor-payment/download/csv', {
        params: {
          search: this.searchQuery,
          perPage: this.perPage,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          paymentMethod: this.paymentMethod,
          approvalStatus: this.approvalStatus,
          vendor: this.vendor,
          createdBy: this.createdBy,
          entity: this.entity,
          dateType: this.dateType,
          dateRange: this.dateRange,
          invoiceType: this.invoiceType,
          myRequest: true,
        },
      })
        .then(response => {
          const data = []
          response.data.items.forEach(obj => {
            data.push({
              invoiceID: obj.invoiceID,
              prCaseID: `${obj.prCaseID}\n${obj.prID}`,
              vendorName: obj.vendorName,
              createdBy: `${obj.createdBy.name || '-'}\n${this.dateFormatWithTime(obj.createdAt)}`,
              // eslint-disable-next-line no-nested-ternary, eqeqeq
              deptCheckStatus: `${(obj.deptCheckStatus.charAt(0).toUpperCase() + obj.deptCheckStatus.slice(1)) || '-'}\n${obj.deptCheckDate ? this.dateFormatWithTime(obj.deptCheckDate) : (obj.deptCheckStatus == 'pending' ? obj.deptPendingSignatory.name : '')}`,
              // eslint-disable-next-line no-nested-ternary, eqeqeq
              cpCheckStatus: `${(obj.cpCheckStatus.charAt(0).toUpperCase() + obj.cpCheckStatus.slice(1)) || '-'}\n${obj.cpCheckDate ? this.dateFormatWithTime(obj.cpCheckDate) : (obj.cpCheckStatus == 'pending' ? obj.cpUser.name : '')}`,
              // eslint-disable-next-line no-nested-ternary, eqeqeq
              paymentStatus: `${(obj.paymentStatus || (obj.deptCheckDate && obj.cpCheckDate)) ? (obj.paymentStatus ? obj.paymentStatus : 'To Process') : '-'}\n${obj.paymentStatus == 'Paid' ? this.dateFormatWithTime(obj.processedDate) : (obj.paymentStatus == 'Cancelled' ? this.dateFormatWithTime(obj.updatedAt) : (obj.paymentStatus == 'financeRemarksStatus' ? (obj.financeRemarksStatus || '-') : ''))}`,
              totalPayable: `$${this.numberFormat(obj.totalPayable) || '-'}\n${obj.paymentMethod || '-'}`,
            })
          })
          let worksheet = null
          if (this.vendor.length || this.createdBy.length || this.entity.length || this.paymentMethod.length || this.approvalStatus.length || this.dateType || this.dateRange) {
            const firstRow = []
            if (this.dateType && this.dateRange) {
              const dateString = `${this.dateType}: ${this.dateRange}`
              firstRow.push(dateString)
            }
            if (this.vendor.length) {
              let vendorString = 'Vendor:'
              const vLength = this.vendor.length - 1
              this.vendor.forEach((v, k) => {
                vendorString += ` ${this.resolveVendorName(v)}`
                if (k < vLength) {
                  vendorString += ','
                }
              })
              firstRow.push(vendorString)
            }
            if (this.createdBy.length) {
              let createdByString = 'Created By:'
              const vLength = this.createdBy.length - 1
              this.createdBy.forEach((v, k) => {
                createdByString += ` ${this.resolveUserName(v)}`
                if (k < vLength) {
                  createdByString += ','
                }
              })
              firstRow.push(createdByString)
            }
            if (this.entity.length) {
              let entityString = 'Entity:'
              const vLength = this.entity.length - 1
              this.entity.forEach((v, k) => {
                entityString += ` ${this.resolveEntityName(v)}`
                if (k < vLength) {
                  entityString += ','
                }
              })
              firstRow.push(entityString)
            }
            if (this.paymentMethod.length) {
              let paymentMethodString = 'Payment Method:'
              const vLength = this.paymentMethod.length - 1
              this.paymentMethod.forEach((v, k) => {
                paymentMethodString += ` ${v}`
                if (k < vLength) {
                  paymentMethodString += ','
                }
              })
              firstRow.push(paymentMethodString)
            }
            if (this.approvalStatus.length) {
              let approvalStatusString = 'Approval Status:'
              const vLength = this.approvalStatus.length - 1
              this.approvalStatus.forEach((v, k) => {
                approvalStatusString += ` ${v}`
                if (k < vLength) {
                  approvalStatusString += ','
                }
              })
              firstRow.push(approvalStatusString)
            }
            worksheet = utils.aoa_to_sheet([
              firstRow,
              [],
              ['Invoice ID', 'Case number', 'Vendor', 'Uploaded By', 'Checks by Dept/HQ', 'Checks by CP', 'Payment Status', 'Payment Details'],
            ])
          } else {
            worksheet = utils.aoa_to_sheet([
              ['Invoice ID', 'Case number', 'Vendor', 'Uploaded By', 'Checks by Dept/HQ', 'Checks by CP', 'Payment Status', 'Payment Details'],
            ])
          }
          utils.sheet_add_json(worksheet, data, { skipHeader: true, origin: -1 })
          if (this.vendor.length === 1) {
            const lastRow = [{
              invoiceID: '',
              prCaseID: '',
              vendorName: '',
              createdBy: '',
              deptCheckStatus: '',
              cpCheckStatus: '',
              paymentStatus: 'Grand Total',
              totalPayable: `$${this.numberFormat(response.data.vendorTotalAmount)}`,
            }]
            utils.sheet_add_json(worksheet, lastRow, { skipHeader: true, origin: -1 })
          }
          worksheet['!cols'] = [{ wch: 15 }, { wch: 25 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 40 }]
          const workbook = utils.book_new()
          utils.book_append_sheet(workbook, worksheet, 'Vendor Payments')
          writeFile(workbook, 'VendorPayments.xlsx', { compression: true })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeButtonText() {
      const param = this.$route.query.invoiceType
      if (param === 'nextPendingPayment') {
        this.buttonText = 'Pending Payment (Next Cycle)'
        this.buttonCount = this.nextPendingPaymentCount
      } else if (param === 'allPendingPayment') {
        this.buttonText = 'Pending Payment (All)'
        this.buttonCount = this.allPendingPaymentCount
      } else if (param === 'paid') {
        this.buttonText = 'Paid'
        this.buttonCount = this.paidCount
      } else {
        this.buttonText = 'Pending Payment (This Cycle)'
        this.buttonCount = this.thisPendingPaymentCount
      }
    },
    removeFromVendorFilter(vendorID) {
      const index = this.vendor.indexOf(vendorID)
      if (index > -1) {
        this.vendor.splice(index, 1)
      }
    },
    removeFromUserFilter(userID) {
      const index = this.createdBy.indexOf(userID)
      if (index > -1) {
        this.createdBy.splice(index, 1)
      }
    },
    removeFromEntityFilter(id) {
      const index = this.entity.indexOf(id)
      if (index > -1) {
        this.entity.splice(index, 1)
      }
    },
    removeFromApprovalStatusFilter(item) {
      const index = this.approvalStatus.indexOf(item)
      if (index > -1) {
        this.approvalStatus.splice(index, 1)
      }
    },
    removeFromPaymentMethodFilter(item) {
      const index = this.paymentMethod.indexOf(item)
      if (index > -1) {
        this.paymentMethod.splice(index, 1)
      }
    },
    dropdownButtonAction() {
      if (!['thisPendingPayment', 'nextPendingPayment', 'allPendingPayment', 'paid'].includes(this.invoiceType)) {
        this.invoiceType = 'thisPendingPayment'
      }
      this.clearAllFilters()
      this.searchQuery = ''
      this.showSearchBar = false
    },
    clearAllFilters() {
      this.vendor = []
      this.createdBy = []
      this.entity = []
      this.paymentMethod = []
      this.approvalStatus = []
      this.dateRange = ''
      this.dateType = ''
    },
    resolveVendorName(id) {
      const index = this.vendorOptions.find(o => o._id === id)
      if (index) {
        return index.companyName || ''
      }
      return ''
    },
    resolveUserName(id) {
      const index = this.userOptions.find(o => o._id === id)
      if (index) {
        return index.name || ''
      }
      return ''
    },
    resolveEntityName(id) {
      const index = this.entityOptions.find(o => o._id === id)
      if (index) {
        return `${index.name} (${index.initialForPO})` || ''
      }
      return ''
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const INVOICE_LIST_APP_STORE_MODULE_NAME = 'app-invoice-list'

    // Register module
    if (!store.hasModule(INVOICE_LIST_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_LIST_APP_STORE_MODULE_NAME, invoiceListsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_LIST_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_LIST_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchInvoiceLists,
      tableColumns,
      perPage,
      currentPage,
      totalInvoice,
      invoiceListData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceTable,
      refetchData,
      currentDataLength,

      toDeletedID,
      invoiceType,
      // allInvoiceCount,
      // departmentPendingCount,
      // cpPendingCount,
      // thisPendingPaymentCount,
      // nextPendingPaymentCount,
      // allPendingPaymentCount,
      // paidCount,
      vendorTotal,
      // mySignPendingCount,
      // UI
      resolveInvoiceStatusVariant,

      // Extra Filter
      paymentMethod,
      approvalStatus,
      vendor,
      createdBy,
      entity,
      dateType,
      dateRange,
    } = useAllInvoiceListList()

    const selectedInvoiceItems = ref([])
    const toggleSelectedInvoiceItems = itemId => {
      const inventoryItemIndex = selectedInvoiceItems.value.indexOf(itemId)

      if (inventoryItemIndex === -1) selectedInvoiceItems.value.push(itemId)
      else selectedInvoiceItems.value.splice(inventoryItemIndex, 1)
    }
    const selectAllInvoiceCheckbox = computed(() => invoiceListData.value.length && (invoiceListData.value.length === selectedInvoiceItems.value.length))
    const isSelectAllInvoiceCheckboxIndeterminate = computed(() => Boolean(selectedInvoiceItems.value.length) && invoiceListData.value.length !== selectedInvoiceItems.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedInvoiceItems.value = val ? invoiceListData.value.map(sData => sData.item) : []
    }

    return {

      // Sidebar
      fetchInvoiceLists,
      tableColumns,
      perPage,
      currentPage,
      totalInvoice,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      invoiceListData,
      currentDataLength,

      // UI
      resolveInvoiceStatusVariant,

      // Filter
      canViewThisAction,

      invoiceType,
      // allInvoiceCount,
      // departmentPendingCount,
      // cpPendingCount,
      // thisPendingPaymentCount,
      // nextPendingPaymentCount,
      // allPendingPaymentCount,
      // paidCount,
      // mySignPendingCount,
      vendorTotal,

      // Extra Filters
      paymentMethod,
      approvalStatus,
      vendor,
      createdBy,
      entity,
      dateType,
      dateRange,

      selectAllInvoiceCheckbox,
      isSelectAllInvoiceCheckboxIndeterminate,
      selectedInvoiceItems,
      toggleSelectedInvoiceItems,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .filter-dropdown{
    width: 100%;
    margin-top: 16px;
  }
  .per-page-selector {
    width: 90px;
  }
  .modal .form-control {
      margin: 0;
  }
  .img-preview-block{
        box-sizing: border-box;
        width: 38px;
        height: 38px;
        border: 1px solid #D2DCEA;
        border-radius: 5px;
    }

  @media (max-width: 767px) {
  .StockLists_fields {
      padding: 10px 0 0 0;
    }

  .new_design_requests {
      margin-top: 10px !important;
  }

  .modal .form-control {
      margin-top: 5px;
  }

  .filter-dropdown{
    width: 100%;
  }
  }

  // start store section css
  .store-wrapper {
     display: flex;
     flex-direction: column;
     border-top: 1px solid #e7edf5;
     max-height: calc(100vh - 125px);
     overflow-y: auto;
  }
  .single-store{
      width: 100%;
      padding: 8px;
  }
  .content-wrapper{
      justify-content: center;
      align-items: flex-start;
      gap: 3px;
      margin: 0;
      text-align: left;
  }
  .content-wrapper .store-name{
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #000000;
  }
  .content-wrapper .company-entity{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #333333;
      margin: 0;
  }
  // ./end store section css
  .status-radio-btn{
      background-color: #F5F8FB;
    padding: 7px 12px 7px 12px;
  }
  .status-radio-btn.btn.btn-outline-primary:hover{
      background-color: #F5F8FB;
  }
  .btn-outline-primary:not(:disabled):not(.disabled).active {
    background: #104D9D;
    color: #fff;
  }
  .radio-group-select span.rounded-number {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    font-weight: 600;
  }
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
  .btn.button-active {
    background-color: #104D9D;
    color:#FFFFFF;
    box-shadow: 0 4px 18px -4px rgba(16, 77, 157, 0.65) !important;
  }
  .btn-outline-primary.btn.button-active:not(:disabled):not(.disabled):active, .btn-outline-primary.btn.button-active:not(:disabled):not(.disabled).active, .btn-outline-primary.btn.button-active:not(:disabled):not(.disabled):focus {
    background-color: #104D9D;
    color:#FFFFFF;
    box-shadow: 0 4px 18px -4px rgba(16, 77, 157, 0.65) !important;
  }
</style>
