import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useAllInvoiceListList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refInvoiceTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'invoiceID',
      label: 'Invoice ID',
      // tdClass: 'width-30',
      sortable: true,
    },
    {
      key: 'prCaseID',
      label: 'Case number',
      sortable: true,
    },
    {
      key: 'vendorName',
      label: 'Vendor',
      sortable: true,
    },
    {
      key: 'createdBy',
      label: 'uploaded BY ',
      sortable: false,
    },
    {
      key: 'deptCheckStatus',
      label: 'Checks by Dept/HQ',
      sortable: true,
    },
    {
      key: 'cpCheckStatus',
      label: 'Checks by CP',
      sortable: true,
    },
    {
      key: 'paymentStatus',
      label: 'payment status',
      sortable: true,
    },
    {
      key: 'totalPayable',
      label: 'Payment details',
      sortable: true,
    },
    {
      key: 'actions',
    },
  ]
  const perPage = ref(route.query.perPage ?? 10)
  const totalInvoice = ref(0)
  const allInvoiceCount = ref(0)
  const departmentPendingCount = ref(0)
  const cpPendingCount = ref(0)
  const thisPendingPaymentCount = ref(0)
  const nextPendingPaymentCount = ref(0)
  const allPendingPaymentCount = ref(0)
  const paidCount = ref(0)
  const mySignPendingCount = ref(0)
  const vendorTotal = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const invoiceType = ref(route.query.invoiceType || 'all')
  const dateType = ref(route.query.dateType || null)
  const dateRange = ref(route.query.dateRange || null)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'invoiceID')
  const isSortDirDesc = ref(route.query.sortDesc ? (route.query.sortDesc === 'true') : true)
  const currentDataLength = ref(0)
  const toDeletedID = ref(null)
  const invoiceListData = ref([])
  const paymentMethod = ref([])
  if (Array.isArray(route.query.paymentMethod)) {
    paymentMethod.value = route.query.paymentMethod
  } else if (route.query.paymentMethod) {
    paymentMethod.value = [route.query.paymentMethod]
  }
  const approvalStatus = ref([])
  if (Array.isArray(route.query.approvalStatus)) {
    approvalStatus.value = route.query.approvalStatus
  } else if (route.query.approvalStatus) {
    approvalStatus.value = [route.query.approvalStatus]
  }
  const vendor = ref([])
  if (Array.isArray(route.query.vendor)) {
    vendor.value = route.query.vendor
  } else if (route.query.vendor) {
    vendor.value = [route.query.vendor]
  }
  const entity = ref([])
  if (Array.isArray(route.query.entity)) {
    entity.value = route.query.entity
  } else if (route.query.entity) {
    entity.value = [route.query.entity]
  }
  const createdBy = ref([store.state.auth.userData._id])
  // if (Array.isArray(route.query.createdBy)) {
  //   createdBy.value = route.query.createdBy
  // } else if (route.query.createdBy) {
  //   createdBy.value = [route.query.createdBy]
  // }
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceTable.value ? refInvoiceTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoice.value,
    }
  })

  const refetchData = () => {
    refInvoiceTable.value.refresh()
  }

  watch([searchQuery, paymentMethod, approvalStatus, invoiceType, vendor, entity, dateType, dateRange], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })
  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchInvoiceLists = (ctx, callback) => {
    store
      .dispatch('app-invoice-list/fetchInvoiceLists', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        paymentMethod: paymentMethod.value,
        approvalStatus: approvalStatus.value,
        vendor: vendor.value,
        createdBy: createdBy.value,
        entity: entity.value,
        dateType: dateType.value,
        dateRange: dateRange.value,
        invoiceType: invoiceType.value,
        myRequest: true,
      },
      { root: true })
      .then(response => {
        const { items, total, vendorTotalAmount } = response.data
        invoiceListData.value = items
        vendorTotal.value = vendorTotalAmount
        // allInvoiceCount.value = response.data.allInvoiceCount
        // departmentPendingCount.value = response.data.departmentPendingCount
        // cpPendingCount.value = response.data.cpPendingCount
        // thisPendingPaymentCount.value = response.data.thisPendingPaymentCount
        // nextPendingPaymentCount.value = response.data.nextPendingPaymentCount
        // allPendingPaymentCount.value = response.data.allPendingPaymentCount
        // paidCount.value = response.data.paidCount
        // mySignPendingCount.value = response.data.mySignPendingCount
        currentDataLength.value = items.length
        callback(items)
        totalInvoice.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              paymentMethod: paymentMethod.value,
              approvalStatus: approvalStatus.value,
              vendor: vendor.value,
              entity: entity.value,
              dateType: dateType.value,
              dateRange: dateRange.value,
              invoiceType: invoiceType.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'reviewed') return 'success'
    if (status === 'checked') return 'success'
    if (status === 'Paid') return 'success'
    if (status === 'Pending') return 'warning'
    if (status === 'Processing') return 'info'
    if (status === 'Rejected') return 'danger'
    if (status === 'Cancelled') return 'danger'
    return 'primary'
  }

  return {
    fetchInvoiceLists,
    tableColumns,
    perPage,
    currentPage,
    totalInvoice,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceTable,
    refetchData,

    toDeletedID,
    invoiceListData,
    currentDataLength,
    allInvoiceCount,
    departmentPendingCount,
    cpPendingCount,
    thisPendingPaymentCount,
    nextPendingPaymentCount,
    allPendingPaymentCount,
    paidCount,
    mySignPendingCount,
    invoiceType,
    vendorTotal,
    resolveInvoiceStatusVariant,

    // Extra Filters
    paymentMethod,
    approvalStatus,
    vendor,
    createdBy,
    entity,
    dateType,
    dateRange,
  }
}
