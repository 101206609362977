<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      v-if="invoiceType == 'all'"
      label="Choose a Type of Date"
      @click.native.stop
    >
      <v-select
        v-model="dateTypeValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        placeholder="Select from list"
        :options="dateTypeOptions"
        :clearable="true"
        :reduce="item => item.code"
      />
    </b-form-group>
    <b-form-group
      v-if="dateTypeValue"
      label="Date Range"
    >
      <b-input-group>
        <flat-pickr
          v-model="dateRangeValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <hr
      v-if="invoiceType == 'all'"
      class="dividerHR filter-divider"
    >
    <b-form-group
      v-if="invoiceType == 'all'"
      label="Approval & Verification Status"
    >
      <b-form-checkbox
        v-for="option in approvalStatusOptions"
        :key="option.code"
        v-model="approvalStatusValue"
        :value="option.code"
        name="flavour-3a"
      >
        {{ option.title }}
      </b-form-checkbox>
    </b-form-group>
    <hr
      v-if="invoiceType == 'all'"
      class="dividerHR filter-divider"
    >
    <b-form-group label="Payment Method">
      <b-form-checkbox
        v-for="option in paymentMethodOptions"
        :key="option.code"
        v-model="paymentMethodValue"
        :value="option.code"
        name="flavour-3a"
      >
        {{ option.title }}
      </b-form-checkbox>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Vendor"
      @click.native.stop
    >
      <v-select
        v-model="vendorValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="companyName"
        placeholder="Select from list"
        multiple
        :options="vendorOptions"
        :reduce="item => item._id"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Entity">
      <b-form-checkbox
        v-for="option in entityOptions"
        :key="option._id"
        v-model="entityValue"
        :value="option._id"
        name="flavour-3a"
      >
        {{ option.name }} ({{ option.initialForPO }})
      </b-form-checkbox>
    </b-form-group>
    <!-- <hr class="dividerHR filter-divider">
    <b-form-group
      label="Uploaded by"
      @click.native.stop
    >
      <v-select
        v-model="createdByValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        multiple
        :options="userOptions"
        :reduce="item => item._id"
      />
    </b-form-group> -->
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    vendor: {
      type: [Array, null],
      default: null,
    },
    vendorOptions: {
      type: Array,
      required: true,
    },
    createdBy: {
      type: [Array, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    entity: {
      type: [Array, null],
      default: null,
    },
    entityOptions: {
      type: Array,
      required: true,
    },
    paymentMethod: {
      type: [Array, null],
      default: null,
    },
    approvalStatus: {
      type: [Array, null],
      default: null,
    },
    dateType: {
      type: [String, null],
      default: null,
    },
    dateRange: {
      type: [String, null],
      default: null,
    },
    invoiceType: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      dateTypeOptions: [
        { title: 'Invoice Uploaded Date', code: 'Invoice Uploaded Date' },
        { title: 'Approved Date', code: 'Approved Date' },
        { title: 'Verified Date', code: 'Verified Date' },
        { title: 'Payment Date', code: 'Payment Date' },
      ],
      approvalStatusOptions: [
        { title: 'Any', code: 'Any' },
        { title: 'Pending Approval', code: 'Pending Approval' },
        { title: 'Pending Verification', code: 'Pending Verification' },
        { title: 'Pending Payment', code: 'Pending Payment' },
        { title: 'Paid', code: 'Paid' },
        { title: 'Rejected', code: 'Rejected' },
      ],
      paymentMethodOptions: [
        { title: 'Cheque', code: 'Cheque' },
        { title: 'Bank Transfer (Direct Debit)', code: 'Bank Transfer (Direct Debit)' },
        { title: 'GIRO (Monthly Auto Deduction)', code: 'GIRO (Monthly Auto Deduction)' },
        { title: 'Petty Cash', code: 'Petty Cash' },
        { title: 'PayNow', code: 'PayNow' },
        { title: 'Telegraph Transfer (Overseas)', code: 'Telegraph Transfer (Overseas)' },
      ],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
      dateRangeValue: this.dateRange,
      dateTypeValue: this.dateType,
      vendorValue: JSON.parse(JSON.stringify(this.vendor)) || [],
      createdByValue: JSON.parse(JSON.stringify(this.createdBy)) || [],
      entityValue: JSON.parse(JSON.stringify(this.entity)) || [],
      paymentMethodValue: JSON.parse(JSON.stringify(this.paymentMethod)) || [],
      approvalStatusValue: JSON.parse(JSON.stringify(this.approvalStatus)) || [],
    }
  },
  watch: {
    dateType: {
      immediate: true,
      handler(val) {
        this.dateTypeValue = val
      },
    },
    dateRange: {
      immediate: true,
      handler(val) {
        this.dateRangeValue = val
      },
    },
    paymentMethod: {
      immediate: true,
      handler(val) {
        this.paymentMethodValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    approvalStatus: {
      immediate: true,
      handler(val) {
        this.approvalStatusValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    vendor: {
      immediate: true,
      handler(val) {
        this.vendorValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    createdBy: {
      immediate: true,
      handler(val) {
        this.createdByValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    entity: {
      immediate: true,
      handler(val) {
        this.entityValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:vendor', this.vendorValue)
      this.$emit('update:createdBy', this.createdByValue)
      this.$emit('update:entity', this.entityValue)
      this.$emit('update:paymentMethod', this.paymentMethodValue)
      this.$emit('update:approvalStatus', this.approvalStatusValue)
      this.$emit('update:dateType', this.dateTypeValue)
      this.$emit('update:dateRange', this.dateRangeValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.vendorValue = []
      this.createdByValue = []
      this.entityValue = []
      this.paymentMethodValue = []
      this.approvalStatusValue = []
      this.dateTypeValue = ''
      this.dateRangeValue = ''

      this.$emit('update:vendor', this.vendorValue)
      this.$emit('update:createdBy', this.createdByValue)
      this.$emit('update:entity', this.entityValue)
      this.$emit('update:paymentMethod', this.paymentMethodValue)
      this.$emit('update:approvalStatus', this.approvalStatusValue)
      this.$emit('update:dateType', this.dateTypeValue)
      this.$emit('update:dateRange', this.dateRangeValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
